<template>
  <div>
    <ul v-if="!isRegistrationWizard" class="biz-breadcrumb">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><router-link to="/supplier/manage/company-info">{{$t('COMPANY_INFO.TITLE')}}</router-link></li>
      <li><a>{{$t('COMMON.EDIT')}}</a></li>
    </ul>
    <div class="page">
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <b-form @submit.prevent="handleSubmit(onSave)">
          <BasicInfo v-model="form.company"/>
          <UserInfo 
            :main="form.mainUser"
            :subs="form.subs"
            @mainInput="(main) => form.mainUser = main"
            @subsInput="(subs) => form.subs = subs"
            :isRegistrationWizard="true"
          />
          <div class="text-center mt-5">
            <j-button variant="primary" size="lg" buttonType="submit">
              {{isRegistrationWizard?$t('COMMON.NEXT'):$t('COMMON.SAVE')}}
            </j-button>
            <div class="mt-4" v-if="!isRegistrationWizard">
              <j-button buttonType="button" variant="light" to="/supplier/manage/company-info">
                <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                {{$t('COMMON.GO_BACK')}}
              </j-button>
            </div>
          </div>
        </b-form>
      </ValidationObserver> 
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
<script>
  
  import CompanyInfoModel from '@/model/CompanyInfo';
  import InitModel from '@/model/Init';
  import UserInfoModel from '@/model/UserInfo';
  import BasicInfo from '@/components/register/BasicInfo.vue';
  import UserInfo from '@/components/register/UserInfo.vue';

  export default {
    props: ["isRegistrationWizard", "notSave", "formData"],
    components: { BasicInfo, UserInfo, },
    data: () => ({
      form: {
        company: {},
        mainUser: {},
        subs: []
      }
    }),
    watch: {
      //TODO: very bad implementation
      form: {
        deep: true,
        handler(){
          // TODO: disabled replicating address to user
        }
      }
    },
    created(){
      Promise.all([
        CompanyInfoModel.getCompanyBasicInfo(this.$store.state.user.companyCode),
        UserInfoModel.getMain(this.$store.state.user.companyCode), //results[0]
        UserInfoModel.getSubs(this.$store.state.user.companyCode), //results[1]
      ]).then((results) => {
        this.form.company = results[0].data;
        this.form.mainUser = results[1].data;
        this.form.subs = results[2].data;
        
        if(this.formData && Object.keys(this.formData).length > 0){
          if(Object.keys(this.formData.company).length > 0)
            this.form.company = this.formData.company.basic;
          if(Object.keys(this.formData.main).length > 0)
            this.form.mainUser   = this.formData.main;
          if(Object.keys(this.formData.subs).length > 0)
            this.form.subs   = this.formData.subs;
        }
        
        InitModel.getRoles(this.$store.state.user.status.spotSupplier).then(result => {
          if(!this.form.mainUser.roles){
            this.form.mainUser.roles = [];
          }
          const roles = result.data.filter(role => !this.form.mainUser.roles.some(m => m.roleCode === role.roleCode));
          this.form.mainUser.roles.push(...roles);
          this.form.mainUser.roles.forEach(role => {
            role.checked = true;
          });
        });

        if(!this.form.company.applicationType) this.form.company.applicationType = '1';
        this.$refs.form.reset();
      }).catch(reason => {
        this.$errorMessage(undefined, {reason});
      });
    },

    methods: {
      async onSave(){ 
        let subs = [].concat(this.form.subs.filter(s=> s.organizationName&&s.name&&s.companyMobilePhone&&s.email));
        let userEmails = [].concat(this.form.mainUser.email, this.form.subs.map(u=>u.email));
        if((new Set(userEmails)).size !== userEmails.length){
          this.$warningMessage(this.$t('COMMON.USER_DUPLICATE_WARNING'));
          return;
        }
        if(this.notSave){
          this.$emit('onSave', {
            company: {
              basic: this.form.company
            },
            main: this.form.mainUser,
            subs: subs
          });
        }
      }
    }
  }
</script>
